import React, { Component } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { FaPhone, FaEnvelope, FaInstagram } from "react-icons/fa"

export default class Footer extends Component {
  render() {
    return (
      <section id='footer' style={{ backgroundColor: "#f2b98d", 
      color: "black" }} className='text-center'>
        <Container>
          <Row className='d-flex justify-content-center'>
            <Col md={3} className='pt-2 pb-2 ' >
              <h3 className='list-title text-center'>Anschrift</h3>
              <p>
                Güngör Kosmetik <br />
                Wilhelm Straße 11<br />
                51643 Gummersbach<br />
                Deutschland
              </p>
            </Col>

            <Col md={3} className='pt-2 pb-2 '>
              <h3 className='list-title'>Kontakt</h3>
              <Button variant='block' href='tel://+491578850134' className='w-100 bg-transparent'>
                <FaPhone className='pr-2' /> +49 157 885 0134
              </Button>
              <Button variant='block' className='w-100 bg-transparent'>
                <FaEnvelope className='pr-2' /> info@gungor-kosmetik.de
              </Button>
              <Button variant='block' 
              href='https://instagram.com/_by_gungor'
              className='w-100 bg-transparent'>
                <FaInstagram className='pr-2' /> @_by_gungor
              </Button>
            </Col>

            <Col md={3} className='pt-2 pb-2 text-center'>
              <h3 className='list-title text-center'>Sitemap</h3>
              <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                <li>
                  <a href='/' className='a-href'>Startseite</a>
                </li>
                <li>
                  <a href='/impressum' className='a-href'>Impressum</a>
                </li>
                <li>
                  <a href='/datenschutz' className='a-href'>Datenschutz</a>
                </li>
              </ul>
            </Col>

          </Row>
        </Container>
      </section>
    )
  }
}
