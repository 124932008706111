import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

import Footer from "../components/Footer"

export default class Impressum extends Component {
    render() {
        return (
            <div>
                <section style={{ height: "100vh" }} className='pt-5 pb-5'>
                    <Container>
                        <h1>Impressum</h1>
                        <h2>Verantwortlich für den Inhalt:</h2>
                        <p>Güngör Şeker Yeşilada<br />
                            Wilhelm Straße 11<br />
                            51643 Gummersbach</p>

                        <h2>Kontakt:</h2>
                        <p>Telefon: +491578850134<br />
                            E-Mail: <a href="mailto:info@gungor-kosmetik.de">
                                info@gungor-kosmetik.de
                            </a></p>

                        <h2>Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG:</h2>
                        <p>DE123456789</p>

                        <h2>Haftungshinweis:</h2>
                        <p>Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>

                        <h2>Datenschutzerklärung:</h2>
                        <p>Für Informationen zur Datenschutzerklärung besuchen Sie bitte unsere Datenschutzseite.</p>

                    </Container>
                </section>

                <Footer />
            </div>
        )
    }
}
